import AttributeCreateButton from "./AttributeCreateButton";
import {Attribute, Product} from "../../types";
import {
    Datagrid,
    FunctionField,
    TranslatableFields,
    List,
    SelectInput
} from "react-admin";
import AttributeEditButton from "./AttributeEditButton";
import {AccordionSection} from "@react-admin/ra-form-layout";
import * as React from "react";
import {useMarketplaces} from "../../utils/useMarketplaces";
import {MarketplaceChoice} from "../../utils/types";
import AttributeEditSmallButton from "./AttributeEditSmallButton";

const ListOfAttributes = (props: { record: Product, editable: boolean }) => {
    const {marketplaces} = useMarketplaces();

    const choices: MarketplaceChoice[] = marketplaces.filter(mp => !mp.isDefault && mp.sourceCode !== 'SL').map(marketplace => {
        return {
            id: marketplace.id,
            name: marketplace.name
        };
    });

    const defaultChoice: MarketplaceChoice = {
        id: 'default',
        name: 'resources.products.show.own_product_attributes'
    };

    choices.unshift(defaultChoice);

    const listFilters = [
        <SelectInput
            source="attribute.defaultMarketplace"
            label="resources.products.show.product_attributes_mp_filter"
            alwaysOn
            choices={choices}
            name="attribute.defaultMarketplace"
            sx={{width: 300}}
        />,
    ];

    return (
        <AccordionSection
            label={'resources.products.show.product_attributes'}
            fullWidth
        >
            <List
                resource={`product-attributes`}
                filter={{product: props.record.id}}
                filters={listFilters}
                perPage={999}
                pagination={false}
                hasCreate={true}
                empty={false}
                sx={{'& .RaList-content': {border: 'none'}}}
                actions={props.editable ? <AttributeCreateButton record={props.record as Product}/> : false}
                disableSyncWithLocation={true}
            >
                <Datagrid bulkActionButtons={false}>
                    <TranslatableFields locales={['de', 'en', 'fr']}>
                        <FunctionField
                            source="attribute.name_translatable"
                            label="resources.products.show.attribute_name"
                            render={(record, source) => {
                                const lang = source.split('.').pop();
                                let value: string = record.attribute.name_translatable[lang];
                                let tip: string = record.attribute.tip_translatable[lang];

                                if (record.attribute?.defaultMarketplace?.name) {
                                    value += ` <small>(${record.attribute.defaultMarketplace.name})</small>`;
                                } else if (tip) {
                                    value += ` ${tip}`;
                                }

                                return (
                                    <div dangerouslySetInnerHTML={{__html: value}}/>
                                );
                            }}
                        />
                        <FunctionField
                            source="value_translatable"
                            label="resources.products.show.attribute_value"
                            render={(record, source) => {
                                const lang = source.split('.').pop();
                                let value: any = record?.value_translatable ? record.value_translatable[lang] : record.value;
                                const type: string = record.attribute.valueType;

                                const findValues = (attribute: Attribute, value: string, language: string): string => {
                                    const splited = value.split(',');

                                    let newValue: string = '';
                                    for (let i = 0; i < splited.length; i++) {
                                        const val = attribute.attributeValues.find(obj => obj.id === parseInt(splited[i], 10));
                                        if (val !== undefined) {
                                            const defaultKey = Object.keys(val.value_translatable)[0];
                                            const defaultValue = val.value_translatable[defaultKey];
                                            newValue += `${val.value_translatable[language] ?? defaultValue}, `;
                                        }
                                    }

                                    return newValue.slice(0, -2);
                                };

                                if (type == 'LIST' || type == 'LIST_MULTIPLE_VALUES') {
                                    return (<AttributeEditSmallButton />);
                                } else if (type == 'MEASUREMENT') {
                                    value = value + ' ' + record.attribute.valueUnit;
                                } else if (type == 'BOOLEAN') {
                                    value = value == "1" ? 'Ja' : 'Nein';
                                }

                                return value;
                            }}
                        />

                    </TranslatableFields>
                    {
                        props.editable ?
                            <AttributeEditButton/>
                            : null
                    }
                </Datagrid>
            </List>
        </AccordionSection>
    );
};

export default ListOfAttributes;
