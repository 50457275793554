import * as React from 'react';
import {
    useTranslate,
    useRecordContext,
    Loading,
    useDataProvider,
    useGetList,
    useLocaleState,
    Edit,
    SimpleForm,
    Toolbar,
    BooleanInput,
    SelectInput,
    usePermissions,
    required,
    minValue,
    DateInput,
    AutocompleteArrayInput
} from 'react-admin';
import {
    Typography,
    Card,
    CardContent,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent
} from '@mui/material';
import {Product, Inventory} from '../types';
import InventoryAllocationCard from "./InventoryAllocationCard";
import {useEffect, useState} from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {AutoSave} from '@react-admin/ra-form-layout';
import {FLAGS_CHOICES} from "./FlagField";
import {hasAccess} from "ra-auth-acl";
import {useMarketplaces} from "../utils/useMarketplaces";

const Aside = () => {
    const record = useRecordContext<Product>();
    return (
        <Box width={{xs: "100%", md: 300, lg: 400}} sx={{minWidth: 300}}>
            {record && <AsideComponent record={record}/>}
            {record && <FlagsComponent record={record}/>}
            {record && <PreOrderComponent record={record}/>}
            {record && <PurchaseListComponent record={record}/>}
        </Box>
    );
};

const AsideComponent = (props: { record: Product }) => {
    const record = props.record;
    const translate = useTranslate();

    const dataProvider = useDataProvider();

    const [inventory, setInventory] = useState<Inventory | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider.getOne(`product/${record.id}/inventory`, {id: undefined})
            .then(({data}) => {
                setInventory(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, [props]);

    if (inventory === null || loading) {
        return <Loading/>;
    }

    return (
        <Box ml={{md: 2}} mt={0}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.stocks_headline')}
                    </Typography>
                    <InventoryAllocationCard
                        marketplace={translate('resources.products.aside.sale_ready_stock')}
                        quantity={inventory.quantity} theme="secondary"
                    />
                    {inventory.allocations.map(allocation => (
                        <InventoryAllocationCard
                            key={allocation.id}
                            marketplace={allocation.marketplace.name}
                            quantity={allocation.quantity} theme="primary"
                        />
                    ))}
                </CardContent>
            </Card>
        </Box>
    );
};
const FlagsComponent = (props: { record: Product }) => {
    const translate = useTranslate();
    const {permissions} = usePermissions();
    const notEditable = !hasAccess(permissions, 'product.edit');

    const tags = [
        {tagId: "trailer"},
        {tagId: "accessory"},
        {tagId: "spare_part"}
    ];

    const {data: templates, isLoading} = useGetList<Product>(`products/shopify-templates`, {
        filter: {
            type: props.record.tags.find(tag => tags.some(t => t.tagId === tag.tagId))?.tagId
        }
    }, {staleTime: 30000});

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Box ml={{md: 2}} mt={2}>
            <Edit sx={{'& .RaEdit-main': {marginTop: 0}}} mutationMode="pessimistic" title={undefined}>
                <SimpleForm
                    resetOptions={{keepDirtyValues: true}}
                    toolbar={<Toolbar><AutoSave debounce={1000}/></Toolbar>}
                    record={{
                        warrantyExchangeEligible: props.record.warrantyExchangeEligible,
                        flag: props.record.flag,
                        shopifyTemplate: props.record.shopifyTemplate
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.settings_headline')}
                    </Typography>
                    <BooleanInput
                        source="warrantyExchangeEligible"
                        fullWidth
                        name="warrantyExchangeEligible"
                        disabled={notEditable}
                        label={`resources.products.list.warrantyExchangeEligible`}
                    />
                    <SelectInput
                        emptyText={'n/a'}
                        source="flag"
                        fullWidth
                        name="flag"
                        choices={FLAGS_CHOICES}
                        disabled={notEditable}
                    />
                    <SelectInput
                        emptyText={'n/a'}
                        source="shopifyTemplate"
                        fullWidth
                        name="shopifyTemplate"
                        label="resources.products.aside.shopify_template"
                        choices={templates}
                        disabled={notEditable}
                    />
                </SimpleForm>
            </Edit>
        </Box>
    );
};

const PreOrderComponent = (props: { record: Product }) => {
    const translate = useTranslate();
    const {permissions} = usePermissions();
    const notEditable = !hasAccess(permissions, 'product.edit');
    const {marketplaces} = useMarketplaces();

    const tags = [
        {tagId: "trailer"},
        {tagId: "accessory"},
        {tagId: "spare_part"}
    ];

    const {data: templates, isLoading} = useGetList<Product>(`products/shopify-templates`, {
        filter: {
            type: props.record.tags.find(tag => tags.some(t => t.tagId === tag.tagId))?.tagId
        }
    }, {staleTime: 30000});

    const today = new Date();
    today.setDate(today.getDate() + 1);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Box ml={{md: 2}} mt={2}>
            <Edit sx={{'& .RaEdit-main': {marginTop: 0}}} mutationMode="pessimistic" title={undefined}>
                <SimpleForm
                    resetOptions={{keepDirtyValues: true}}
                    toolbar={<Toolbar><AutoSave debounce={1000}/></Toolbar>}
                    record={{
                        preorderMarketplaces: props.record.preorderMarketplaces,
                        preorderDeliveryDate: props.record.preorderDeliveryDate
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.preorder_headline')}
                    </Typography>

                    <AutocompleteArrayInput
                        name="preorderMarketplaces"
                        source="preorderMarketplaces"
                        // Temporary allow only online shop
                        // In the future, we will allow all marketplaces
                        choices={marketplaces.filter(marketplace => marketplace.sourceCode == 'ONLINE_SHOP')}
                        label="resources.products.aside.preorder_marketplaces"
                        fullWidth
                        disableCloseOnSelect={true}
                        blurOnSelect={false}
                        disabled={notEditable}
                    />
                    <DateInput
                        name="preorderDeliveryDate"
                        source="preorderDeliveryDate"
                        label="resources.products.aside.preorder_date"
                        validate={[minValue(`${year}-${month}-${day}`)]}
                        fullWidth
                        disabled={notEditable}
                    />
                </SimpleForm>
            </Edit>
        </Box>
    );
};
const PurchaseListComponent = (props: { record: Product }) => {
    const record = props.record;
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();

    const {data: list, isLoading} = useGetList(`product-purchase-orders`, {
            filter: {product: record.id},
            sort: {field: 'arrivalDate', order: 'ASC'},
            pagination: {page: 1, perPage: 999}
        },
        {staleTime: 30000}
    );

    const types = {
        PURCHASE_ORDER: {
            icon: ShoppingCartIcon,
            label: 'resources.products.aside.purchase'
        },
        PROJECTED_ARRIVAL: {
            icon: DirectionsBoatIcon,
            label: 'resources.products.aside.arrival'
        },
    };

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Box ml={{md: 2}} mt={2}> {list?.length ?
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.purchase_order_headline')}
                    </Typography>
                    <Stepper orientation="vertical" sx={{mt: 1}}>
                        {list?.map((purchaseOrder: any) => {
                            const type = types[purchaseOrder.type];
                            return (
                                <Step
                                    key={`${purchaseOrder.id}-po`}
                                    expanded
                                    active
                                    completed
                                >
                                    <StepLabel
                                        icon={
                                            <type.icon
                                                color="disabled"
                                                sx={{pl: 0.5, fontSize: '1.25rem'}}
                                            />
                                        }
                                        title={translate(type.label)}
                                    >
                                        {new Date(purchaseOrder.arrivalDate).toLocaleString(locale, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        })}
                                    </StepLabel>
                                    <StepContent title={translate(type.label)}>
                                        <Typography variant="body2" color="textSecondary">
                                            {translate('resources.products.aside.ordered_items', {
                                                smart_count: purchaseOrder.orderedItems
                                            })}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {translate('resources.products.aside.remaining_items', {
                                                smart_count: purchaseOrder.remainingItems
                                            })}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {translate('resources.products.aside.available_items', {
                                                smart_count: purchaseOrder.availableItems
                                            })}
                                        </Typography>
                                    </StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>
                </CardContent>
            </Card> : null}
        </Box>
    );
};

export default Aside;
