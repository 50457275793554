import * as React from 'react';
import { Chip, SxProps } from '@mui/material';
import { useTranslate, useRecordContext } from 'react-admin';
import {CustomerApplication} from '../types';
import { applicationStates } from './applicationStates';

const StatusChip = (props: { sx: SxProps }) => {
    const translate = useTranslate();
    const record = useRecordContext<CustomerApplication>();
    if (!record) {
        return null;
    }

    const status = applicationStates[record.status];

    return (
        <Chip
            color={status.color}
            size="small"
            key={status.id}
            label={translate(status.translationKey)}
            sx={props.sx}
        />
    );
};

StatusChip.defaultProps = {
    sx: {},
};

export default StatusChip;
