type stateType = {
    id: string;
    translationKey: string;
    color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
};

type statesCollection = {
    [key: string]: stateType;
};

export const applicationStates: statesCollection = {
    new: {
        id: 'new',
        translationKey: 'resources.forms.customerApplication.status.new',
        color: 'default',
    },
    awaiting_second_step: {
        id: 'awaiting_second_step',
        translationKey: 'resources.forms.customerApplication.status.awaiting_second_step',
        color: 'primary',
    },
    request_rejected: {
        id: 'request_rejected',
        translationKey: 'resources.forms.customerApplication.status.request_rejected',
        color: 'warning',
    },
    awaiting_approval: {
        id: 'awaiting_approval',
        translationKey: 'resources.forms.customerApplication.status.awaiting_approval',
        color: 'info',
    },
    registered: {
        id: 'registered',
        translationKey: 'resources.forms.customerApplication.status.registered',
        color: 'success',
    },
    registration_rejected: {
        id: 'registration_rejected',
        translationKey: 'resources.forms.customerApplication.status.registration_rejected',
        color: 'error',
    },
    closed: {
        id: 'closed',
        translationKey: 'resources.forms.customerApplication.status.closed',
        color: 'secondary',
    }
};
