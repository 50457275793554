import {useCallback} from "react";
import * as React from "react";
import {
    required,
    SimpleForm,
    useCreate,
    useGetList,
    useListContext,
    useNotify,
    AutocompleteArrayInput
} from "react-admin";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {Product, ProductMatch} from "../../types";
import {productNameWithColorAndSize} from "../../utils/productNameWithColorAndSize";

const FormNewCompatibleProducts = (props: { record: Product, type: 'trailer' | 'accessory' | 'spare_part' }) => {
    const [create] = useCreate();
    const notify = useNotify();

    const save = useCallback(
        async values => {
            try {
                for (let i = 0; i < values.matched.length; i++) {
                    const data = {...values, matched: values.matched[i]}
                    await create(`product-matches`, {data}, {returnPromise: true});
                }

                notify('ra.notification.created', {
                    type: 'success',
                    autoHideDuration: 5000,
                    messageArgs: {smart_count: 1}
                });
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    console.log(e.errors);
                    return error.body.error.errors;
                }
            }
        },
        [create, notify]
    );

    const matchedProducts = useListContext<ProductMatch>();

    const filter = {
        tags: {
            tagId: props.type
        },
        archivedIncluded: true
    };

    const {data: productChoices, isLoading} = useGetList<Product>(`products`, {
        filter,
        sort: {field: 'name', order: 'ASC'},
        pagination: {page: 1, perPage: 9999}
    }, {staleTime: 30000});


    if (isLoading || !matchedProducts.data)
        return null;

    const products = (productChoices as Product[]).filter(product => !matchedProducts.data.some(pv => pv.matched.id === product.id) && product.id !== props.record.id);
    const productsChoices = products.map(product => ({
        id: product['@id'],
        name: productNameWithColorAndSize(product)
    }));

    return (
        <SimpleForm sx={{width: '500px'}} onSubmit={save}>
            <AutocompleteArrayInput
                fullWidth={true}
                disableCloseOnSelect={true}
                blurOnSelect={false}
                source="matched"
                choices={productsChoices}
                label={`resources.products.show.matched.products`}
                validate={[required()]}
                name="matched"
            />
        </SimpleForm>
    );
};

const AddNewCompatibleProducts = (props: { record: Product, type: 'trailer' | 'accessory' | 'spare_part' }) => {

    return (
        <CreateInDialogButton
            record={{product: `/products/${props.record.id}`, type: props.type}}
            title={'resources.products.show.matched.add_new_product'}
            ButtonProps={{label: 'resources.products.show.matched.add_new_product'}}
        >
            <FormNewCompatibleProducts record={props.record} type={props.type}/>
        </CreateInDialogButton>
    );
};

export default AddNewCompatibleProducts;
