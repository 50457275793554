import * as React from 'react';
import {DateInput, SearchInput, SelectInput,} from 'react-admin';

const TRAILER_TYPES = [
    {id: 'Kid', name: 'Kid'},
    {id: 'Dog', name: 'Dog'},
    {id: 'Cargo', name: 'Cargo'}
];

const axleComplaintsFilters = () => {
    return [
        <SelectInput
            alwaysOn
            source="trailerCategory"
            choices={TRAILER_TYPES}
            label={`resources.forms.axleComplaint.trailer_type`}
        />,
        <SearchInput
            key="companyName"
            source="companyName"
            name="companyName"
            sx={{
                '& .MuiInputBase-root': {
                    height: 48,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0
                }
            }}
        />,
        <DateInput
            source="createdAt_gte"
            name="createdAt_gte"
            label={`resources.forms.axleComplaint.requested_since`}
        />,
        <DateInput
            source="createdAt_lte"
            name="createdAt_lte"
            label={`resources.forms.axleComplaint.requested_before`}
        />
    ];
}

export default axleComplaintsFilters;
