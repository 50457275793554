import * as React from "react";
import {
    DeleteWithConfirmButton,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TranslatableInputs
} from "react-admin";
import {MarkdownInput} from "@react-admin/ra-markdown";
import {EditInDialogButton} from "@react-admin/ra-form-layout";

const ContentEditButton = () => {
    return (
        <EditInDialogButton
            fullWidth
            maxWidth="lg"
            mutationMode="optimistic"
            title=" "
        >
            <SimpleForm
                toolbar={
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <SaveButton/>
                        <DeleteWithConfirmButton
                            redirect={false}
                            mutationMode="optimistic"
                            confirmTitle=" "
                        />
                    </Toolbar>}
            >
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <TextInput source="name_translatable"
                               label="resources.products.show.content.name"/>
                    <TextInput source="vendor_translatable"
                               label="resources.products.show.content.vendor"/>
                    <TextInput source="deliveryTime_translatable"
                               label="resources.products.show.content.delivery_time"/>
                    <TextInput source="sizeDescription_translatable"
                               label="resources.products.show.content.size"/>
                    <TextInput source="metaTitle_translatable"
                               label="resources.products.show.content.meta_title"/>
                    <TextInput source="metaDescription_translatable"
                               label="resources.products.show.content.meta_description"/>
                    <MarkdownInput
                        source="description_translatable"
                        label="resources.products.show.content.description"
                        toolbarItems={[
                            ['heading', 'bold', 'italic', 'strike'],
                            ['hr', 'quote'],
                            ['ul', 'ol', 'indent', 'outdent'],
                            ['table', 'link'],
                            ['code', 'codeblock'],
                        ]}
                    />
                </TranslatableInputs>
            </SimpleForm>
        </EditInDialogButton>
    );
};

export default ContentEditButton;
