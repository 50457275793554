import * as React from 'react';
import {
    List,
    TextField,
    useTranslate,
    DatagridConfigurable,
    TopToolbar,
    SelectColumnsButton, ExportButton,
    useStore, downloadCSV, FunctionField,
    DateField, FilterButton
} from 'react-admin';
import {useMediaQuery, Theme} from '@mui/material';

import {useDefineAppLocation} from '@react-admin/ra-navigation';
import {CustomerApplication} from "../types";
import jsonExport from 'jsonexport/dist';
import customerApplicationsFilters from './customerApplicationsFilters';
import StatusChip from "./StatusChip";

const CustomerApplicationListActions = () => {
    return (
        <TopToolbar>
            <FilterButton/>
            <SelectColumnsButton preferenceKey="forms.customerApplication.list"/>
            <ExportButton maxResults={10000}/>
        </TopToolbar>
    );
};

const CustomerApplicationList = () => {
    useDefineAppLocation('forms.customer_applications');
    const t = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    let [availableColumns] = useStore('preferences.forms.customerApplication.list.availableColumns');
    let [selectedColumns] = useStore('preferences.forms.customerApplication.list.columns');

    const exporter = (applications: CustomerApplication[]) => {
        const forExport = applications.map(application => {
            if (selectedColumns !== undefined) {

                let applicationToExport = {};

                for (let i = 0; i < selectedColumns.length; i++) {
                    const column = availableColumns.find((column) => column.index === selectedColumns[i]);
                    if (column) {
                        applicationToExport[t(`resources.forms.customerApplication.fields.${column.source}`)] = application[column.source];
                    }
                }

                return applicationToExport;
            }

            return {
                [t(`resources.forms.customerApplication.fields.requestId`)]: application.requestId,
                [t(`resources.forms.customerApplication.fields.companyName`)]: application.companyName,
                [t(`resources.forms.customerApplication.fields.zipCode`)]: application.zipCode,
                [t(`resources.forms.customerApplication.fields.city`)]: application.city,
                [t(`resources.forms.customerApplication.fields.street`)]: application.street,
                [t(`resources.forms.customerApplication.fields.country`)]: application.country,
                [t(`resources.forms.customerApplication.fields.email`)]: application.email,
                [t(`resources.forms.customerApplication.fields.createdAt`)]: application.createdAt,
                [t(`resources.forms.customerApplication.fields.createdAt`)]: application.createdAt,
                [t(`resources.forms.customerApplication.fields.createdAt`)]: application.createdAt,
                [t(`resources.forms.customerApplication.fields.status`)]: application.status
            };
        });
        jsonExport(forExport, {}, (err, csv) => {
            downloadCSV(csv, 'applications_list');
        });
    };
    const filters: JSX.Element[] = customerApplicationsFilters();

    return (
        <List
            exporter={exporter}
            filters={filters}
            perPage={25}
            actions={<CustomerApplicationListActions/>}
            sx={{marginTop: isSmall ? undefined : 1}}
        >
            <DatagridConfigurable
                omit={[
                    'website', 'hasLocalStore', 'hasRepairCenter', 'phoneNumber', 'zipCode', 'city', 'street'
                ]}
                preferenceKey="forms.customerApplication.list"
                bulkActionButtons={false}
                rowClick="show"
            >
                <TextField source="requestId" label={`resources.forms.customerApplication.fields.requestId`}
                           sortable={false}/>
                <TextField source="companyName" label={`resources.forms.customerApplication.fields.companyName`}
                           sortable={false}/>
                <TextField source="zipCode" label={`resources.forms.customerApplication.fields.zipCode`}
                           sortable={false}/>
                <TextField source="city" label={`resources.forms.customerApplication.fields.city`}
                           sortable={false}/>
                <TextField source="street" label={`resources.forms.customerApplication.fields.street`}
                           sortable={false}/>
                <TextField source="country" label={`resources.forms.customerApplication.fields.country`}/>
                <TextField source="email" label={`resources.forms.customerApplication.fields.email`} sortable={false}/>
                <DateField
                    source="createdAt"
                    label={`resources.forms.customerApplication.fields.createdAt`}
                    locales="de-DE"
                    options={{
                        weekday: undefined,
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }}
                    sortable={true}
                />
                <TextField source="website" label={`resources.forms.customerApplication.fields.website`}
                           sortable={false}/>
                <TextField source="hasLocalStore" label={`resources.forms.customerApplication.fields.hasLocalStore`}
                           sortable={true}/>
                <TextField source="hasRepairCenter" sortable={true}
                           label={`resources.forms.customerApplication.fields.hasRepairCenter`}/>
                <TextField source="phoneNumber" label={`resources.forms.customerApplication.fields.phoneNumber`}
                           sortable={false}/>
                <FunctionField
                    sortable={true}
                    source="status"
                    label={`resources.forms.customerApplication.fields.status`}
                    render={() => <StatusChip/>}
                />
            </DatagridConfigurable>
        </List>
    );
};

export default CustomerApplicationList;
