import * as React from 'react';
import {LoadingIndicator} from 'react-admin';
import {SolarAppBar} from '@react-admin/ra-navigation';

const CustomAppBar = () => (
    <SolarAppBar sx={{displayPrint: 'none'}}>
        <LoadingIndicator/>
    </SolarAppBar>
);

export default CustomAppBar;
