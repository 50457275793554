import * as React from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    TextInput,
    FunctionField,
    DateField,
    SelectInput,
    TextField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import OrdersListFilters from './OrdersListFilters';
import StatusColumn from './StatusColumn';
import statusesCollection from './orderStatus';
import { Order } from '../types';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const SOURCE = {
    ONLINE_SHOP: 'Online shop',
    SMARTVIEW: 'Smartview',
    FRESSNAPF: 'Fressnapf DE',
    Fressnapf: 'Fressnapf DE',
    Fressnapf_AT: 'Fressnapf AT',
    Decathlon_DE: 'Decathlon DE',
    OTHER: 'Other',
};

const listFilters = [
    <TextInput
        label={`resources.order.filters.search`}
        alwaysOn
        source={`q`}
        name={`q`}
        variant={`outlined`}
    />,
    <SelectInput
        source="status"
        name="status"
        translateChoice
        choices={statusesCollection}
    />,
];

const OrderList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    useDefineAppLocation('sales.orders');

    return (
        <List
            filters={isSmall ? listFilters : undefined}
            perPage={25}
            aside={<OrdersListFilters />}
        >
            {/*{isXsmall ? (*/}
            {/*    <MobileGrid />*/}
            {/*) : (*/}
            <Datagrid bulkActionButtons={false} optimized rowClick="show">
                <TextField
                    source="id"
                    sortable={false}
                    label="resources.order.cart_reference_id"
                />
                <TextField source="number" label="resources.order.number" />
                <DateField
                    source="createdAt"
                    label="resources.order.initiated_at"
                    locales="de-DE"
                    options={{
                        weekday: undefined,
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }}
                    sortable={true}
                />
                <FunctionField
                    source="status"
                    label="resources.order.status"
                    render={() => <StatusColumn />}
                />
                <FunctionField
                    source="totalPriceWithDiscount"
                    label="resources.order.totalAmount"
                    sortable={true}
                    textAlign={`right`}
                    render={(record: any) =>
                        record.totalPriceWithDiscount.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                            currencySign: 'accounting',
                        })
                    }
                />
                <BooleanField
                    looseValue={true}
                    source="dealerId"
                    sortable={false}
                    label="resources.order.is_sent_to_dealer"
                />
                <BooleanField
                    looseValue={true}
                    source="dhlPostNumber"
                    sortable={false}
                    label="resources.order.is_sent_to_parcel_locker"
                />
                <BooleanField
                    looseValue={true}
                    source="discount"
                    sortable={false}
                    label="resources.order.discounted"
                />
                <FunctionField
                    source="source"
                    label="resources.order.source"
                    sortable={true}
                    textAlign={`left`}
                    render={(record: Order): any =>
                        // @ts-ignore
                        SOURCE[record.source] ?? SOURCE.OTHER
                    }
                />
            </Datagrid>
            {/*)}*/}
        </List>
    );
};

export default OrderList;
