import {
    AutocompleteInput,
    required, SelectInput,
    SimpleForm,
    useGetList,
    useListContext, useRefresh, TopToolbar
} from "react-admin";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import * as React from "react";
import {Attribute, Product, ProductVariant} from "../../types";
import {productNameWithColorAndSize} from "../../utils/productNameWithColorAndSize";
import {Typography} from '@mui/material';
import {useState} from "react";

const VariantCreateForm = (props: { record: Product }) => {
    const variants = useListContext<ProductVariant>();
    const attributes = useGetList<Attribute>('attributes', {
        pagination: {page: 1, perPage: 999},
        filter: {code: ['colour', 'size', 'seats']}
    }, {staleTime: 10000});

    const tags = props.record.tags.map((tag) => tag.tagId);
    const filter = {};

    if (tags.includes('trailer')) {
        filter['tags'] = ['trailer'];

        if (tags.includes('kid')) {
            filter['tags'].push('kid');
        } else if (tags.includes('cargo')) {
            filter['tags'].push('cargo');
        } else if (tags.includes('dog')) {
            filter['tags'].push('dog');
        } else if (tags.includes('bob')) {
            filter['tags'].push('bob');
        }

    } else {
        const accessory = tags.includes('accessory');
        const sparePart = tags.includes('spare_part');
        if (accessory || sparePart) {
            filter['tags'] = {
                tagId: accessory ? 'accessory' : 'spare_part'
            };
        }
    }

    const {data: productChoices, isLoading} = useGetList<Product>(`products`, {
        filter,
        sort: {field: 'name', order: 'ASC'},
        pagination: {page: 1, perPage: 9999}
    }, {staleTime: 30000});


    if (attributes.isLoading || isLoading || !variants.data)
        return null;

    const products = (productChoices as Product[]).filter(product => !variants.data.some(pv => pv.variant.id === product.id) && product.id !== props.record.id);
    const productsChoices = products.map(product => ({
        id: product['@id'],
        name: productNameWithColorAndSize(product)
    }));

    return (
        <SimpleForm sx={{width: '500px'}}>
            <AutocompleteInput
                optionText="name"
                optionValue="id"
                fullWidth={true}
                choices={productsChoices}
                name="variant"
                validate={[required()]}
                label={`resources.products.show.variants.variant`}
            />
            <SelectInput
                fullWidth={true}
                validate={[required()]}
                name="attribute"
                source="attribute"
                optionText="name"
                label={`resources.products.show.variants.variant_attribute`}
                optionValue="@id"
                choices={attributes.data}
            />
        </SimpleForm>
    );
};

const VariantCreateButton = (props: { record: Product }) => {
    const refresh = useRefresh();
    const variants = useListContext<ProductVariant>();
    const vgc = (variants.data && variants.data.length > 0) ? variants.data[0].variantGroupId : 'n/a';

    return (
        <TopToolbar>
            <Typography variant="caption" color="textSecondary">
                VGC: {vgc}
            </Typography>
            <CreateInDialogButton
                record={{product: `/products/${props.record.id}`}}
                title={'resources.products.show.variants.add_new_variant'}
                mutationOptions={{
                    onSuccess: () => {
                        refresh();
                    }
                }}
            >
                <VariantCreateForm record={props.record}/>
            </CreateInDialogButton>
        </TopToolbar>
    );
};

export default VariantCreateButton;
