import * as React from 'react';
import {Box, Card, CardContent, Grid, Link} from '@mui/material';
import {Labeled, TextField, Show, useRecordContext, DateField, FunctionField} from 'react-admin';
import {Helmet} from 'react-helmet';
import {AxleComplaint} from "../types";
import {format} from 'date-fns';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const CustomHelmet = () => {
    const record = useRecordContext<AxleComplaint>();

    if (!record) {
        return null;
    }

    return (
        <Helmet>
            <title>
                Complaint reported at {format(new Date(record.createdAt), 'dd.MM.yyyy HH:mm')}
            </title>
        </Helmet>
    );
}

const AxleComplaintsShow = () => {
    useDefineAppLocation('forms.axle_complaints.axle_complaint_show');

    return (
        <Show
            sx={{
                '& .RaShow-card': {
                    border: 'none',
                    background: 'transparent',
                },
            }}
        >
            <Box width="100%">
                <CustomHelmet/>
                <Card>
                    <CardContent>
                        <Box sx={{width: '100%', padding: 2}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="createdAt"
                                             label={`resources.forms.axleComplaint.fields.createdAt`}>
                                        <DateField source="createdAt" key="createdAt"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="notifierType"
                                             label={`resources.forms.axleComplaint.fields.notifierType`}>
                                        <TextField source="notifierType" key="notifierType"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="name" label={`resources.forms.axleComplaint.fields.name`}>
                                        <TextField source="name" key="name"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="surname" label={`resources.forms.axleComplaint.fields.surname`}>
                                        <TextField source="surname" key="surname"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="companyName"
                                             label={`resources.forms.axleComplaint.fields.companyName`}>
                                        <TextField source="companyName" key="companyName" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="email" label={`resources.forms.axleComplaint.fields.email`}>
                                        <TextField source="email" key="email"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="phoneNumber"
                                             label={`resources.forms.axleComplaint.fields.phoneNumber`}>
                                        <TextField source="phoneNumber" key="phoneNumber" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="clientId" label={`resources.forms.axleComplaint.fields.clientId`}>
                                        <TextField source="clientId" key="clientId" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="postalCode"
                                             label={`resources.forms.axleComplaint.fields.postalCode`}>
                                        <TextField source="postalCode" key="postalCode"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="city" label={`resources.forms.axleComplaint.fields.city`}>
                                        <TextField source="city" key="city" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="street" label={`resources.forms.axleComplaint.fields.street`}>
                                        <TextField source="street" key="street" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="houseNumber"
                                             label={`resources.forms.axleComplaint.fields.houseNumber`}>
                                        <TextField source="houseNumber" key="houseNumber" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="serialNumber"
                                             label={`resources.forms.axleComplaint.fields.serialNumber`}>
                                        <TextField source="serialNumber" key="serialNumber" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="reason" label={`resources.forms.axleComplaint.fields.reason`}>
                                        <TextField source="reason" key="reason" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="purchasePlace"
                                             label={`resources.forms.axleComplaint.fields.purchasePlace`}>
                                        <TextField source="purchasePlace" key="purchasePlace" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="purchaseDate"
                                             label={`resources.forms.axleComplaint.fields.purchaseDate`}>
                                        <TextField source="purchaseDate" key="purchaseDate" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="axleSide" label={`resources.forms.axleComplaint.fields.axleSide`}>
                                        <TextField source="axleSide" key="axleSide" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="isBroken" label={`resources.forms.axleComplaint.fields.isBroken`}>
                                        <TextField source="isBroken" key="isBroken" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="isBentOnly"
                                             label={`resources.forms.axleComplaint.fields.isBentOnly`}>
                                        <TextField source="isBentOnly" key="isBentOnly" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="isWheelJammed"
                                             label={`resources.forms.axleComplaint.fields.isWheelJammed`}>
                                        <TextField source="isWheelJammed" key="isWheelJammed" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="isWheelLoss"
                                             label={`resources.forms.axleComplaint.fields.isWheelLoss`}>
                                        <TextField source="isWheelLoss" key="isWheelLoss" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="isBoughtNew"
                                             label={`resources.forms.axleComplaint.fields.isBoughtNew`}>
                                        <TextField source="isBoughtNew" key="isBoughtNew" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="orderInformation"
                                             label={`resources.forms.axleComplaint.fields.orderInformation`}>
                                        <TextField source="orderInformation" key="orderInformation" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="orderNumber"
                                             label={`resources.forms.axleComplaint.fields.orderNumber`}>
                                        <TextField source="orderNumber" key="orderNumber" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="invoiceNumber"
                                             label={`resources.forms.axleComplaint.fields.invoiceNumber`}>
                                        <TextField source="invoiceNumber" key="invoiceNumber" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="orderFirstName"
                                             label={`resources.forms.axleComplaint.fields.orderFirstName`}>
                                        <TextField source="orderFirstName" key="orderFirstName" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="orderLastName"
                                             label={`resources.forms.axleComplaint.fields.orderLastName`}>
                                        <TextField source="orderLastName" key="orderLastName" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="orderDate"
                                             label={`resources.forms.axleComplaint.fields.orderDate`}>
                                        <TextField source="orderDate" key="orderDate" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="bikeType" label={`resources.forms.axleComplaint.fields.bikeType`}>
                                        <TextField source="bikeType" key="bikeType" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="frequency"
                                             label={`resources.forms.axleComplaint.fields.frequency`}>
                                        <TextField source="frequency" key="frequency" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="trailerMaintained"
                                             label={`resources.forms.axleComplaint.fields.trailerMaintained`}>
                                        <TextField source="trailerMaintained" key="trailerMaintained"
                                                   emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="maintenanceDate"
                                             label={`resources.forms.axleComplaint.fields.maintenanceDate`}>
                                        <TextField source="maintenanceDate" key="maintenanceDate" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="identificationCircumstances"
                                             label={`resources.forms.axleComplaint.fields.identificationCircumstances`}>
                                        <TextField source="identificationCircumstances"
                                                   key="identificationCircumstances"
                                                   emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="trailerSuffered"
                                             label={`resources.forms.axleComplaint.fields.trailerSuffered`}>
                                        <TextField source="trailerSuffered" key="trailerSuffered" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="otherDamages"
                                             label={`resources.forms.axleComplaint.fields.otherDamages`}>
                                        <TextField source="otherDamages" key="otherDamages" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="someoneHurt"
                                             label={`resources.forms.axleComplaint.fields.someoneHurt`}>
                                        <TextField source="someoneHurt" key="someoneHurt" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="injuryDetails"
                                             label={`resources.forms.axleComplaint.fields.injuryDetails`}>
                                        <TextField source="injuryDetails" key="injuryDetails" emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="lat"
                                             label={`resources.forms.axleComplaint.fields.respondentCountryCode`}>
                                        <TextField source="respondentCountryCode" key="respondentCountryCode"
                                                   emptyText={`n/a`}/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Labeled source="files" label={`resources.forms.axleComplaint.fields.files`}>
                                        <FunctionField
                                            source="files"
                                            name="files"
                                            render={(request: AxleComplaint) => {
                                                return request?.files?.map((file, index) => (
                                                    <Link
                                                        href={file}
                                                        key={index}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        sx={{display: 'block', marginBottom: 1}}
                                                    >
                                                        {`File ${index + 1}`}
                                                    </Link>
                                                ));
                                            }}
                                        />
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Show>
    );
};

export default AxleComplaintsShow;
