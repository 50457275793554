import * as React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    ResourceBreadcrumbItems,
    useSolarSidebarActiveMenu
} from '@react-admin/ra-navigation';
import {useCreatePath, useTranslate, RaRecord} from 'react-admin';

const CustomBreadcrumb = (props: { dashboard: any }) => {
    const translate = useTranslate();
    const createPath = useCreatePath();
    const [, setActiveMenu] = useSolarSidebarActiveMenu();

    const editLabel = translate('ra.action.edit');
    const createLabel = translate('ra.action.create');
    return (
        <Breadcrumb dashboard={props.dashboard}
                    sx={{
                        fontSize: 'small',
                        '& a': {
                            pointerEvents: 'visible',
                        },
                        '& .RaBreadcrumb-list': {
                            margin: 0,
                            padding: 0,
                        },
                        zIndex: 10,
                        maxWidth: 'fit-content',
                        displayPrint: 'none'
                    }}
        >
            <ResourceBreadcrumbItems resources={['dashboard']}/>
            <BreadcrumbItem
                onClick={() => setActiveMenu('catalog')}
                name="catalog"
                label={`resources.catalog_menu_label`}
                sx={{cursor: 'pointer'}}
            >
                <BreadcrumbItem
                    name="products"
                    label={`resources.products.main_menu_label`}
                    to="/products"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={({record}: { record?: RaRecord }): string =>
                            `${editLabel} ${
                                record ? `"${record.name}"` : '...'
                            }`
                        }
                        to={({record}: { record?: RaRecord }): string =>
                            record
                                ? createPath({
                                    resource: 'products',
                                    id: record.id,
                                    type: 'edit',
                                })
                                : ''
                        }
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="back_in_stock"
                    label={`resources.back_in_stock.main_menu_label`}
                    to="/back-in-stock"
                />
            </BreadcrumbItem>
            <BreadcrumbItem
                name="settings"
                label={`resources.server.main_menu_label`}
                sx={{cursor: 'pointer'}}
                onClick={() => setActiveMenu('settings')}
            >
                <BreadcrumbItem
                    name="marketplaces"
                    label={`resources.marketplace.main_menu_label`}
                    to="/marketplaces"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={({record}: { record?: RaRecord }): string =>
                            `${editLabel} ${
                                record ? `"${record.name}" marketplace` : '...'
                            }`
                        }
                        to={({record}: { record?: RaRecord }): string =>
                            record
                                ? createPath({
                                    resource: 'marketplaces',
                                    id: record.id,
                                    type: 'edit',
                                })
                                : ''
                        }
                    />
                    <BreadcrumbItem
                        name="marketplace"
                        label={({marketplaceRecord}: { marketplaceRecord?: RaRecord }): string =>
                            marketplaceRecord ? `"${marketplaceRecord.name}" marketplace` : 'Marketplace'
                        }
                        to={({marketplaceRecord}: { marketplaceRecord?: RaRecord }): string =>
                            marketplaceRecord
                                ? createPath({
                                    resource: 'marketplaces',
                                    id: marketplaceRecord.id,
                                    type: 'edit',
                                })
                                : ''
                        }
                    >
                        <BreadcrumbItem
                            name="category_edit"
                            label={({categoryRecord}: { categoryRecord?: RaRecord }): string =>
                                `${editLabel} ${
                                    categoryRecord ? `"${categoryRecord.name}" category` : '...'
                                }`
                            }
                        />
                        <BreadcrumbItem
                            name="category"
                            label={({categoryRecord}: { categoryRecord?: RaRecord }): string =>
                                categoryRecord ? `"${categoryRecord.name}" category` : '...'
                            }
                            to={({categoryRecord}: { categoryRecord?: RaRecord }): string =>
                                categoryRecord
                                    ? createPath({
                                        resource: 'categories',
                                        id: categoryRecord.id,
                                        type: 'edit',
                                    })
                                    : ''
                            }
                        >
                            <BreadcrumbItem name="tree_edit" label={`resources.category.edit.tree_label`}/>
                        </BreadcrumbItem>
                    </BreadcrumbItem>
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="settings_cron"
                    label={`resources.server.cron.main_menu_label`}
                    to="/server/cron-jobs"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={({record}: { record?: RaRecord }): string =>
                            `${editLabel} ${
                                record ? `"${record.name}"` : '...'
                            }`
                        }
                    />

                </BreadcrumbItem>
                <BreadcrumbItem
                    name="settings_user"
                    label={`resources.user.name`}
                    to="/user"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={({record}: { record?: RaRecord }): string =>
                            `${editLabel} ${
                                record ? `"${record.firstName} ${record.lastName}"` : '...'
                            }`
                        }
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="settings_attributes"
                    label={`resources.attributes.main_menu_label`}
                    to="/attributes"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={editLabel}
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="dealers"
                    label={`resources.dealers.main_menu_label`}
                    to="/dealers"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={editLabel}
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="dealers_locations"
                    label={`resources.dealers.locations.main_menu_label`}
                    to="/dealers"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={editLabel}
                    />
                </BreadcrumbItem>
            </BreadcrumbItem>
            <BreadcrumbItem
                name="sales"
                label={`resources.sales_menu_label`}
                sx={{cursor: 'pointer'}}
                onClick={() => setActiveMenu('sales')}
            >
                <BreadcrumbItem
                    name="orders"
                    label={`resources.order.main_menu_label`}
                    to="/order"
                >
                    <BreadcrumbItem
                        name="show"
                        label={({record}: { record?: RaRecord }): string =>
                            record?.number ? `#${record.number}` : 'Preview Order'
                        }
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="coupons"
                    label={`resources.coupon.main_menu_label`}
                    to="/coupon"
                >
                    <BreadcrumbItem
                        name="edit"
                        label={({record}: { record?: RaRecord }): string =>
                            `${editLabel} ${
                                record ? `"${record.name}" coupon` : '...'
                            }`
                        }
                    />
                </BreadcrumbItem>
            </BreadcrumbItem>
            <BreadcrumbItem
                name="reports"
                label={`resources.reports.main_menu_label`}
                sx={{cursor: 'pointer'}}
                onClick={() => setActiveMenu('reports')}
            >
                <BreadcrumbItem
                    name="productsAllocations"
                    label={`resources.reports.products_allocations_main_menu_label`}
                    to="/product-marketplace-allocations"
                />
                <BreadcrumbItem
                    name="reports_list"
                    label={`resources.reports.reports.main_menu_label_bc`}
                    to="/reports"
                >
                    <BreadcrumbItem
                        name="create"
                        label={`resources.reports.reports.create_new_report`}
                    />
                    <BreadcrumbItem
                        name="show"
                        label={({record}: { record?: RaRecord }): string =>
                            `${record ? `"${record.name}"` : '...'}`
                        }
                    />
                </BreadcrumbItem>
            </BreadcrumbItem>
            <BreadcrumbItem
                name="forms"
                label={`resources.forms.main_menu_label`}
                sx={{cursor: 'pointer'}}
                onClick={() => setActiveMenu('forms')}
            >
                <BreadcrumbItem
                    name="hitch_requests"
                    label={`resources.forms.hitch_request_main_menu_label`}
                />
                <BreadcrumbItem
                    name="customer_applications"
                    label={`resources.forms.customer_applications_main_menu_label`}
                    to="/customer-applications"
                >
                    <BreadcrumbItem
                        name="customer_application_show"
                        label={({record}: { record?: RaRecord }): string =>
                            `${record ? `"${record.companyName}"` : '...'}`
                        }
                    />
                </BreadcrumbItem>
                <BreadcrumbItem
                    name="axle_complaints"
                    label={`resources.forms.axle_complaints_main_menu_label`}
                    to="/axle-complaints"
                >
                    <BreadcrumbItem
                        name="axle_complaint_show"
                        label={`...`}
                    />
                </BreadcrumbItem>
            </BreadcrumbItem>
        </Breadcrumb>
    );
};

export default CustomBreadcrumb;
