import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import { TextField, useRecordContext } from 'react-admin';
import {Review} from '../types';

const ReviewShow = () => {
    const record = useRecordContext<Review>();
    if (!record) return null;
    return (
        <Card>
            <CardContent>
                <TextField
                    source="content"
                    align="center"
                    component="p"
                    gutterBottom
                />
            </CardContent>
        </Card>
    );
};

export default ReviewShow;
