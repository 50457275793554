import {
    AutocompleteArrayInput, AutocompleteInput, DeleteWithConfirmButton, FormDataConsumer,
    Identifier, Loading, maxLength,
    maxValue, minLength,
    minValue, number, NumberInput, required, SaveButton,
    SelectInput,
    SimpleForm, TextInput, Toolbar,
    TranslatableInputs, useDataProvider, useGetList, useGetOne,
    useListContext, useRefresh, useTranslate
} from "react-admin";
import {Alert, InputAdornment} from "@mui/material";
import {MarkdownInput} from "@react-admin/ra-markdown";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import * as React from "react";
import {Attribute, AttributeProduct, Product} from "../../types";
import {useState, useEffect, useMemo} from "react";

const AttributeCreateForm = () => {
    const t = useTranslate();
    const dp = useDataProvider();

    const [attributeId, setAttributeId] = useState<Identifier | undefined>(undefined);
    const [attribute, setAttribute] = useState<Attribute | undefined>(undefined);
    const productAttributes = useListContext();
    const attributes = useGetList<Attribute>('attributes', {pagination: {page: 1, perPage: 999}}, {staleTime: 10000});

    useEffect(() => {
        if (attributeId !== undefined) {
            dp.getOne<Attribute>(`attributes`, {
                id: attributeId as Identifier,
            })
                .then(({data}) => {
                    setAttribute(data);
                })
                .catch(error => {
                    console.log(error.data);
                });
        }
    }, [attributeId]);

    if (attributes.isLoading || productAttributes.isLoading)
        return <Loading/>;

    const attributesChoices: Attribute[] = attributes.data as Attribute[];
    const attributesProducts: AttributeProduct[] = productAttributes.data as AttributeProduct[];
    const allAttributes = attributesChoices.filter(attr => !attributesProducts.some(pa => pa.attribute['@id'] === attr['@id']));
    const choices = allAttributes.map(attr => ({
        id: attr.id,
        '@id': attr['@id'],
        name: attr.defaultMarketplace ? `${attr.name} ${attr?.tip ? attr.tip : ''} (${attr.defaultMarketplace.name})` : `${attr.name} ${attr?.tip ? attr.tip : ''}`
    }));

    const attributeUpdate = (attributeIri: any) => {
        const parts = attributeIri.split('/');
        const id = parseInt(parts[parts.length - 1], 10);
        setAttributeId(id);
    };

    const Field = () => {
        let type: string = '';
        let validations: any[] = [required()];

        if (attribute !== undefined) {
            type = attribute.valueType;

            if (attribute.valueValidations) {
                const rules = attribute.valueValidations.split(',');

                for (let i = 0; i < rules.length; i++) {
                    const rule = rules[i].split('|');
                    const ruleName = rule[0];
                    const ruleValue = rule[1];

                    switch (ruleName) {
                        case 'MIN':
                            validations.push(minValue(parseInt(ruleValue, 10)));
                            break;
                        case 'MAX':
                            validations.push(maxValue(parseInt(ruleValue, 10)));
                            break;
                        case 'MAX_LENGTH':
                            validations.push(maxLength(parseInt(ruleValue, 10)));
                            break;
                        case 'MIN_LENGTH':
                            validations.push(minLength(parseInt(ruleValue, 10)));
                            break;
                    }
                }
            }
        }

        switch (type) {
            case 'INTEGER':
                validations.push(number());
                return (
                    <NumberInput
                        name="value"
                        source="value"
                        label="resources.products.show.attribute_value"
                        hiddenLabel={false}
                        step={1}
                        validate={validations}
                        fullWidth
                    />
                );
            case 'MEASUREMENT':
                validations.push(number());
                return (
                    <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                        <NumberInput
                            source="value_translatable"
                            label=" "
                            hiddenLabel={true}
                            step={0.01}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        {attribute?.valueUnit}
                                    </InputAdornment>
                                ),
                            }}
                            validate={validations}
                        />
                    </TranslatableInputs>
                );
            case 'LONG_TEXT':
                const simpleToolbarItems = [['heading', 'ul']];
                const extendedToolbarItems = [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'indent', 'outdent'],
                    ['table', 'link'],
                    ['code', 'codeblock'],
                ];

                return (
                    <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                        <MarkdownInput
                            source="value_translatable"
                            label="resources.products.show.attribute_value"
                            toolbarItems={attribute?.defaultMarketplace ? simpleToolbarItems : extendedToolbarItems}
                            validate={validations}
                        />
                    </TranslatableInputs>
                );
            case 'LIST_MULTIPLE_VALUES':
                return (
                    <AutocompleteArrayInput
                        source="value"
                        choices={attribute?.attributeValues}
                        optionValue="id"
                        optionText="value"
                        label="resources.products.show.attribute_value"
                        fullWidth
                        validate={validations}
                        format={collection => {
                            if (typeof collection === 'string') {
                                return collection.split(',');
                            }

                            return collection;
                        }}
                    />
                );
            case 'LIST':
                return (
                    <SelectInput
                        source="value"
                        name="value"
                        optionValue="id"
                        optionText="value"
                        choices={attribute?.attributeValues}
                        fullWidth
                        validate={validations}
                    />
                );
            case 'BOOLEAN':
                return (
                    <SelectInput
                        source="value"
                        name="value"
                        optionValue="id"
                        optionText="value"
                        choices={[
                            {id: "1", value: "ra.boolean.true"},
                            {id: "0", value: "ra.boolean.false"}
                        ]}
                        fullWidth
                        validate={validations}
                    />
                );
            case 'TEXT':
                return (
                    <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                        <TextInput
                            source="value_translatable"
                            label="resources.products.show.attribute_value"
                            validate={validations}
                        />
                    </TranslatableInputs>
                );
            default:
                return (
                    <Alert severity="warning" sx={{width: "calc(100% - 32px)"}}>
                        {t('resources.products.show.select_attribute_first')}
                    </Alert>
                );
        }
    };

    return (
        <SimpleForm>
            <AutocompleteInput
                optionText="name"
                optionValue="@id"
                fullWidth={true}
                choices={choices}
                name="attribute"
                validate={[required()]}
                label={`resources.products.show.attribute_name`}
                onChange={(field) => {
                    attributeUpdate(field);
                }}
            />
            {Field()}
        </SimpleForm>
    );
};


const AttributeCreateButton = (props: { record: Product }) => {
    const dp = useDataProvider();
    const refresh = useRefresh();

    const [attributeId, setAttributeId] = useState<Identifier | undefined>(undefined);
    const [attribute, setAttribute] = useState<Attribute | undefined>(undefined);

    useEffect(() => {
        if (attributeId !== undefined) {
            dp.getOne<Attribute>(`attributes`, {
                id: attributeId as Identifier,
            })
                .then(({data}) => {
                    setAttribute(data);
                })
                .catch(error => {
                    console.log(error.data);
                });
        }
    }, [attributeId]);

    let maxWidthValue: "lg" | "xs" = "lg";

    if (attribute?.valueType === 'INTEGER' || attribute?.valueType === 'BOOLEAN') {
        maxWidthValue = "xs";
    }

    return (
        <CreateInDialogButton
            record={{product: `/products/${props.record.id}`}}
            fullWidth
            maxWidth={maxWidthValue}
            transform={(data) => {
                const type = attribute?.valueType;
                const languages = ['de', 'en', 'fr'];

                data['value'] = type === 'LIST_MULTIPLE_VALUES' ? data.value.join(',') : data?.value?.toString();

                if (type === 'LIST_MULTIPLE_VALUES' || type === 'LIST' || type === 'INTEGER' || type === 'BOOLEAN') {
                    data.value_translatable = {};

                    for (let i = 0; i < languages.length; i++) {
                        data.value_translatable[languages[i]] = data.value;
                    }
                }

                return data;
            }}
            mutationOptions={{
                onSuccess: () => {
                    refresh();
                    setAttribute(undefined);
                    setAttributeId(undefined);
                }
            }}
        >
           <AttributeCreateForm />
        </CreateInDialogButton>
    );
};

export default AttributeCreateButton;
