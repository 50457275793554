import * as React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslate } from 'react-admin';
import { Targets } from '../coupon/targetTypes';

import { Order } from '../types';
import { TableCellRight } from './TableCellRight';
import DiscountValue from './DiscountValue';
import RefundedProductItemChip from './RefundedProductItemChip';

const Totals = (props: { order: Order }) => {
    const { order } = props;
    const translate = useTranslate();

    return (
        <Table sx={{ minWidth: '35em' }}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        {translate('resources.order.show.products_subtotal')}
                    </TableCell>
                    <TableCellRight>
                        {parseFloat(order?.subtotal)?.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })}
                    </TableCellRight>
                </TableRow>
                <TableRow>
                    <TableCell>
                        {translate('resources.order.show.shipping_cost')}
                    </TableCell>
                    <TableCellRight>
                        {order?.shippingTotalCost?.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })}
                        &nbsp;
                        {order.isShippingCostRefunded ? (
                            <RefundedProductItemChip />
                        ) : (
                            ''
                        )}
                    </TableCellRight>
                </TableRow>

                {order?.orderDiscount && order?.discount ? (
                    <TableRow>
                        <TableCell>
                            {translate('resources.order.show.discount')}{' '}
                        </TableCell>
                        <TableCellRight>
                            -
                            {parseFloat(order?.discount).toLocaleString(
                                'de-DE',
                                {
                                    style: 'currency',
                                    currency: 'EUR',
                                }
                            )}
                        </TableCellRight>
                    </TableRow>
                ) : null}

                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                        {translate('resources.order.show.order_total')}
                    </TableCell>
                    <TableCellRight sx={{ fontWeight: 'bold' }}>
                        {order?.orderDiscount
                            ? order?.totalPriceWithDiscount?.toLocaleString(
                                  'de-DE',
                                  {
                                      style: 'currency',
                                      currency: 'EUR',
                                  }
                              )
                            : order?.totalPrice?.toLocaleString('de-DE', {
                                  style: 'currency',
                                  currency: 'EUR',
                              })}
                    </TableCellRight>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default Totals;
