import {stringify, parse} from 'query-string';
import {fetchUtils} from 'react-admin';
import type {DataProvider, GetListParams, GetManyParams, GetManyReferenceParams} from 'react-admin';
import {removeTrailingSlash} from '../removeTrailingSlash';
import {addTreeMethodsBasedOnChildren} from "./treeDataProvider";

export default (
    entrypoint: string,
    httpClient = fetchUtils.fetchJson,
): DataProvider => {
    const apiUrl = new URL(entrypoint, window.location.href);

    const defaultGetListMethod = async (resource: string, params: GetListParams) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${removeTrailingSlash(
            apiUrl.toString(),
        )}/${resource}?${stringify(query)}`;
        const {json, headers} = await httpClient(url);

        return {
            data: json,
            total: parseInt((headers.get('content-range')?.split('/')?.slice(-1)?.pop() as string), 10),
            pageInfo: {
                hasNextPage: true,
                hasPreviousPage: page > 1,
            },
        };
    };
    const hydraGetListMethod = async (resource: string, params: GetListParams) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;

        const query = {itemsPerPage: 10, page: 1};

        for (let key in params.filter) {
            if (Array.isArray(params.filter[key])) {
                for (let i in params.filter[key]) {
                    query[`${key}[${i}]`] = params.filter[key][i];
                }
            } else if (typeof params.filter[key] === 'object' && params.filter[key].constructor === Object) {
                const keys = Object.keys(params.filter[key]);
                for (let i in keys) {
                    query[`${key}.${keys[i]}`] = params.filter[key][keys[i]];
                }
            } else {
                const operators = {'_gte': 'after', '_lte': 'before'};
                const operator = operators[key.slice(-4)];

                if (operator) {
                    query[`${key.slice(0, -4)}[${operator}]`] = params.filter[key];
                } else {
                    query[key] = params.filter[key];
                }
            }
        }

        query[`order[${field}]`] = order;
        query.itemsPerPage = perPage;
        query.page = page;

        const url = `${removeTrailingSlash(
            apiUrl.toString(),
        )}/${resource}?${stringify(query)}`;
        const {json} = await httpClient(url, {
            headers: new Headers({Accept: 'application/ld+json'})
        });

        const meta = json['hydra:view'];

        const currentPage = parseInt((parse(meta['@id']).page as string), 10);
        const lastPage = parseInt((parse(meta['hydra:last']).page as string), 10);

        return {
            data: json['hydra:member'],
            total: json['hydra:totalItems'],
            pageInfo: {
                hasNextPage: currentPage < lastPage,
                hasPreviousPage: page > 1,
            },
        };
    };

    const defaultGetManyMethod = async (resource: string, params: GetManyParams) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        const url = `${removeTrailingSlash(
            apiUrl.toString(),
        )}/${resource}?${stringify(query)}`;
        const {json} = await httpClient(url);

        return {
            data: json,
        };
    };
    const hydraGetManyMethod = async (resource: string, params: GetManyParams) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        const url = `${removeTrailingSlash(
            apiUrl.toString(),
        )}/${resource}?${stringify(query)}`;
        const {json} = await httpClient(url, {
            headers: new Headers({Accept: 'application/ld+json'})
        });

        return {
            data: json['hydra:member']
        };
    };

    const defaultGetManyReferenceMethod = async (resource: string, params: GetManyReferenceParams) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${removeTrailingSlash(
            apiUrl.toString(),
        )}/${resource}?${stringify(query)}`;
        const {json} = await httpClient(url);

        return {
            data: json,
            pageInfo: {
                hasNextPage: true,
                hasPreviousPage: page > 1,
            },
        };
    };
    const hydraGetManyReferenceMethod = async (resource: string, params: GetManyReferenceParams) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;

        const query = {
            [params.target]: params.id,
            ...params.filter
        };

        query[`order[${field}]`] = order;
        query.itemsPerPage = perPage;
        query.page = page;

        const url = `${removeTrailingSlash(
            apiUrl.toString(),
        )}/${resource}?${stringify(query)}`;
        const {json} = await httpClient(url, {
            headers: new Headers({Accept: 'application/ld+json'})
        });

        const meta = json['hydra:view'];

        const currentPage = parseInt((parse(meta['@id']).page as string), 10);
        const lastPage = parseInt((parse(meta['hydra:last']).page as string), 10);

        return {
            data: json['hydra:member'],
            pageInfo: {
                asNextPage: currentPage < lastPage,
                hasPreviousPage: page > 1,
            },
        };
    };

    const hydraResponseResources: string[] = [
        'product-marketplace-allocations',
        'product-purchase-orders',
        'product-manuals',
        'category-trees',
        'product-attributes',
        'hitch-requests',
        'axle-complaints',
        'customer-applications',
        'customer-application-events',
        'quick-view-reports',
        'product-variants',
        'attribute-values',
        'dealer-addresses',
        'product-matches',
        'products/media',
        'order-reviews',
        'marketplaces',
        'report-files',
        'categories',
        'attributes',
        'products',
        'dealers',
        'reports',
    ];

    const dataProvider = {
        getList: async (resource, params) => hydraResponseResources.includes(resource) ? hydraGetListMethod(resource, params) : defaultGetListMethod(resource, params),

        getOne: async (resource, params) => {
            const url = `${removeTrailingSlash(apiUrl.toString())}/${resource}${params.id ? `/${params.id}` : ''}?${stringify(params?.meta)}`;
            const {json} = await httpClient(url);

            return {
                data: json,
            };
        },
        getMany: async (resource, params) => hydraResponseResources.includes(resource) ? hydraGetManyMethod(resource, params) : defaultGetManyMethod(resource, params),
        getManyReference: async (resource, params) => hydraResponseResources.includes(resource) ? hydraGetManyReferenceMethod(resource, params) : defaultGetManyReferenceMethod(resource, params),

        update: async (resource, params) => {
            const url = `${removeTrailingSlash(apiUrl.toString())}/${resource}/${
                params.id
            }`;
            const {json} = await httpClient(url, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            });

            return {
                data: json ? json : {id: params.id}
            };
        },

        patch: async (resource, params) => {
            const url = `${removeTrailingSlash(apiUrl.toString())}/${resource}${params.id ? `/${params.id}` : ''}?${stringify(params?.meta)}`;

            const {json} = await httpClient(url, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            });

            return {
                data: json ? json : {id: params.id}
            };
        },

        updateMany: async (resource, params) => {
            const responses = await Promise.all(
                params.ids.map((id) => {
                    const url = `${removeTrailingSlash(
                        apiUrl.toString(),
                    )}/${resource}/${id}`;

                    return httpClient(url, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    });
                }),
            );

            return {data: responses.map(({json}) => json.id)};
        },

        create: async (resource, params) => {
            const url = `${removeTrailingSlash(apiUrl.toString())}/${resource}`;
            const {json} = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(params.data),
            });

            return {
                data: json,
            };
        },

        delete: async (resource, params) => {
            const url = `${removeTrailingSlash(apiUrl.toString())}/${resource}/${
                params.id
            }`;
            await httpClient(url, {
                method: 'DELETE',
            });

            return {
                data: params.id,
            };
        },

        deleteMany: async (resource, params) => {
            await Promise.all(
                params.ids.map((id) => {
                    const url = `${removeTrailingSlash(
                        apiUrl.toString(),
                    )}/${resource}/${id}`;

                    return httpClient(url, {
                        method: 'DELETE',
                    });
                }),
            );

            return {
                data: [],
            };
        },
    };

    return addTreeMethodsBasedOnChildren(dataProvider);
};
