import {
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useGetList,
    useTranslate,
    Labeled,
    Loading,
    SimpleForm,
    Toolbar,
    SaveButton,
    useRefresh, FunctionField, useLocaleState
} from "react-admin";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    useMediaQuery,
    Theme, CardActions, ButtonGroup, Link, Stepper, Step, StepLabel, StepContent, Button, Paper
} from "@mui/material";
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {CustomerApplication} from "../types";
import * as React from "react";
import {applicationStates} from './applicationStates';
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import countryCodes, {CountryProperty} from 'country-codes-list';
import {Helmet} from 'react-helmet';
import IosShareIcon from '@mui/icons-material/IosShare';

type Transition = {
    id: string;
    target: string;
};

const Aside = () => {
    const record = useRecordContext<CustomerApplication>();
    useDefineAppLocation('forms.customer_applications.customer_application_show', {record});

    return (
        <Box width={{xs: "100%", md: 300, lg: 400}} ml={{md: 2}} mt={{xs: 2, md: 0}}
             sx={{minWidth: 300, displayPrint: 'none'}}>
            {record && <StatusPanel record={record}/>}
            {record && <HistoryComponent record={record}/>}
        </Box>
    );
};

const CustomHelmet = () => {
    const record = useRecordContext<CustomerApplication>();

    if (!record) {
        return null;
    }

    return (
        <Helmet>
            <title>
                Customer application: {record.companyName}
            </title>
        </Helmet>
    );
}


const HistoryComponent = (props: { record: CustomerApplication }) => {
    const record = props.record;
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const {data: list, isLoading} = useGetList(`customer-application-events`, {
            filter: {customerApplication: record.id},
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 999}
        },
        {staleTime: 30000}
    );

    const types = {
        CREATE: {
            icon: FiberNewIcon,
            label: 'resources.forms.customerApplication.history.created'
        },
        STATUS_UPDATE: {
            icon: AutorenewIcon,
            label: 'resources.forms.customerApplication.history.statusUpdate'
        },
        UPDATE: {
            icon: EditIcon,
            label: 'resources.forms.customerApplication.history.dataUpdate'
        },
        DATA_SEND_TO_SL: {
            icon: IosShareIcon,
            label: 'resources.forms.customerApplication.history.slDataSend'
        },
    };

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Box width={{xs: "100%", md: 300, lg: 400}} ml={{md: 0}} mt={2} sx={{minWidth: 300}}>
            {list?.length ?
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.forms.customerApplication.historyHeadline')}
                        </Typography>
                        <Stepper activeStep={activeStep} orientation="vertical" sx={{mt: 1}}>
                            {list?.map((step: any, index) => {
                                const type = types[step.eventType] ?? types["UPDATE"];
                                const data = Object.entries(step.newData).map(([key, value]) => {
                                    if (value && typeof value === 'object') {
                                        value = JSON.stringify(value, null, 2)
                                    } else if (value && typeof value === 'string') {
                                        value = value.length > 54 ? value.substring(0, 54) + '...' : value;
                                    }

                                    return `${key}: ${value}`;
                                });

                                const time = new Date(step.eventTime).toLocaleString(locale, {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                });
                                return (
                                    <Step
                                        key={`${step.id}-cu_step`}
                                        sx={{
                                            '& .MuiStepLabel-labelContainer': {
                                                fontSize: '12px',
                                            },
                                        }}
                                    >
                                        <StepLabel
                                            optional={`(${time} ${translate('resources.forms.customerApplication.history.by')} ${step.authorName})`}
                                        >
                                            {translate(type.label)}
                                        </StepLabel>
                                        <StepContent>
                                            {
                                                data.map((d) =>
                                                    <Typography variant="caption" color="text.secondary"
                                                                component={"p"}>
                                                        {d}
                                                    </Typography>)
                                            }
                                            <Box sx={{mb: 2}}>
                                                <Button
                                                    size={"small"}
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{
                                                        mt: 1,
                                                        mr: 1,
                                                        display: index === list.length - 1 ? 'none' : 'inline-flex'
                                                    }}
                                                >
                                                    {translate('resources.forms.customerApplication.history.next')}
                                                </Button>
                                                <Button
                                                    size={"small"}
                                                    onClick={handleBack}
                                                    sx={{mt: 1, mr: 1, display: index === 0 ? 'none' : 'inline-flex'}}
                                                >
                                                    {translate('resources.forms.customerApplication.history.previous')}
                                                </Button>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </CardContent>
                </Card> : null}
        </Box>
    );
};


const Transitions = (props: { transitions: Transition[] | undefined }) => {
    const {transitions} = props;
    const t = useTranslate();
    const refresh = useRefresh();

    if (!transitions || transitions.length === 0) {
        return null;
    }

    return (
        <ButtonGroup variant="contained" aria-label="" fullWidth={true}>
            {transitions.map((transition) => {
                    const status = applicationStates[transition.target];

                    return (
                        <EditInDialogButton
                            title={`resources.forms.customerApplication.transitions.${transition.id}ConfirmationTitle`}
                            mutationMode="pessimistic"
                            mutationOptions={{
                                onMutate: (mutate) => {
                                    mutate.id = `${mutate.id}/transitions`;
                                    return mutate;
                                },
                                onSuccess: () => {
                                    refresh();
                                }
                            }}
                            ButtonProps={
                                {
                                    label: `resources.forms.customerApplication.transitions.${transition.id}`,
                                    startIcon: false,
                                    // @ts-ignore
                                    color: status.color
                                }
                            }
                        >
                            <SimpleForm
                                toolbar={
                                    <Toolbar>
                                        <SaveButton label={`ra.action.confirm`} icon={<ThumbUpIcon/>} alwaysEnable/>
                                    </Toolbar>
                                }
                                record={{transition: transition.id}}
                            >
                                <Typography variant="body1" gutterBottom>
                                    {t(`resources.forms.customerApplication.transitions.${transition.id}ConfirmationDesc`)}
                                </Typography>
                            </SimpleForm>
                        </EditInDialogButton>
                    );
                }
            )}
        </ButtonGroup>
    );
};
const StatusPanel = (props: { record: CustomerApplication }) => {
    const record = props.record;
    const t = useTranslate();

    const {data: transitions, isLoading} = useGetList<Transition>(`customer-applications/${record.id}/transitions`);

    if (isLoading) {
        return <Loading/>;
    }

    const status = applicationStates[record.status];

    return (
        <Box width={{xs: "100%", md: 300, lg: 400}} ml={{md: 0}} mt={{xs: 2, md: 0}} sx={{minWidth: 300}}>
            <Card>
                <CardContent sx={{width: "100%"}}>
                    <Labeled
                        source="status"
                        label={`resources.forms.customerApplication.currentStatus`}
                        sx={{textAlign: "center", width: "100%"}}
                    >
                        <Typography variant="h6" gutterBottom>
                            {t(status.translationKey)}
                        </Typography>
                    </Labeled>
                </CardContent>
                <CardActions sx={{display: transitions?.length ? 'flex' : 'none'}}>
                    <Transitions transitions={transitions}/>
                </CardActions>
            </Card>
        </Box>
    );
};

export const CustomerApplicationShow = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const t = useTranslate();

    return (<Show
            aside={<Aside/>}
            sx={{
                '& .RaShow-main': {display: isSmall ? 'block' : 'flex'},
            }}
        >
            <Box sx={{display: "flex", gap: 4, flexWrap: "wrap"}}>
                <CustomHelmet/>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                        <SimpleShowLayout sx={{flex: 1}}>
                            <Typography variant="h6" gutterBottom>
                                {t('resources.forms.customerApplication.firstStepHeadline')}
                            </Typography>
                            <TextField source="requestId"
                                       label={"resources.forms.customerApplication.fields.requestId"}
                                       emptyText={'n/a'}/>
                            <FunctionField
                                label={"resources.forms.customerApplication.fields.address"}
                                source="files"
                                name="files"
                                render={(request: CustomerApplication) => {
                                    let country = request.country;
                                    const find = countryCodes.findOne('countryCode' as CountryProperty, country);

                                    if (find) {
                                        country = `${find.flag} ${find.countryNameEn}`;
                                    }

                                    return (
                                        <>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.companyName}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.street}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.zipCode + ' ' + request.city}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {country}
                                            </Typography>
                                        </>
                                    )
                                }}
                            />
                            <TextField source="phoneNumber"
                                       label={"resources.forms.customerApplication.fields.phoneNumber"}
                                       emptyText={'n/a'}/>
                            <TextField source="email" label={"resources.forms.customerApplication.fields.email"}
                                       emptyText={'n/a'}/>
                            <TextField source="surname" label={"resources.forms.customerApplication.fields.surname"}
                                       emptyText={'n/a'}/>
                            <TextField source="name" label={"resources.forms.customerApplication.fields.name"}/>
                            <TextField source="website" label={"resources.forms.customerApplication.fields.website"}
                                       emptyText={'n/a'}/>
                            <FunctionField
                                label={false}
                                source="hasLocalStore"
                                name="hasLocalStore"
                                render={(request: CustomerApplication) => {
                                    if (request.localStoreName) {
                                        return (
                                            <Labeled
                                                label={"resources.forms.customerApplication.fields.localStoreName"}>
                                                <Typography variant="inherit" color="text.primary" component={"p"}>
                                                    {request.localStoreName}
                                                </Typography>
                                            </Labeled>
                                        );
                                    }

                                    return (
                                        <Labeled label={"resources.forms.customerApplication.fields.hasLocalStore"}>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.hasLocalStore}
                                            </Typography>
                                        </Labeled>
                                    );
                                }}
                            />
                            <TextField source="hasRepairCenter"
                                       label={"resources.forms.customerApplication.fields.hasRepairCenter"}
                                       emptyText={'n/a'}/>
                            <TextField source="message" label={"resources.forms.customerApplication.fields.message"}
                                       emptyText={'n/a'}/>
                            <FunctionField
                                label={"resources.forms.customerApplication.fields.files"}
                                source="files"
                                name="files"
                                render={(request: CustomerApplication) => {
                                    return request?.files?.map((file, index) => (
                                        <Link href={file} key={index} target="_blank" rel="noopener noreferrer">
                                            {`File ${index + 1}`}
                                        </Link>
                                    ));
                                }}
                            />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <SimpleShowLayout sx={{flex: 1}}>
                            <Typography variant="h6" gutterBottom>
                                {t('resources.forms.customerApplication.secondStepHeadline')}
                            </Typography>
                            <TextField
                                source="industry"
                                label={"resources.forms.customerApplication.fields.industry"}
                                emptyText={'n/a'}
                            />
                            <FunctionField
                                label={"resources.forms.customerApplication.fields.warehouseAddress"}
                                source="warehouseCountry"
                                name="warehouseCountry"
                                emptyText={'n/a'}
                                render={(request: CustomerApplication) => {
                                    if (request.warehouseCompanyName === 'n/a') {
                                        return (
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                n/a
                                            </Typography>
                                        );
                                    }

                                    let country = request.warehouseCountry;
                                    const find = countryCodes.findOne('countryCode' as CountryProperty, country);

                                    if (find) {
                                        country = `${find.flag} ${find.countryNameEn}`;
                                    }

                                    return (
                                        <>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.warehouseCompanyName}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.warehouseContactPerson}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.warehouseStreet}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.warehousePostalCode + ' ' + request.warehouseCity}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {country}
                                            </Typography>
                                        </>
                                    )
                                }}
                            />
                            <TextField
                                source="warehousePhoneNumber"
                                label={"resources.forms.customerApplication.fields.warehousePhoneNumber"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="warehouseEmail"
                                label={"resources.forms.customerApplication.fields.warehouseEmail"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="warehouseIsLocalStore"
                                label={"resources.forms.customerApplication.fields.warehouseIsLocalStore"}
                                emptyText={'n/a'}
                            />
                            <FunctionField
                                label={"resources.forms.customerApplication.fields.localStoreAddress"}
                                source="localStoreName"
                                name="localStoreName"
                                render={(request: CustomerApplication) => {
                                    if (request.localStoreName === 'n/a') {
                                        return (
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                n/a
                                            </Typography>
                                        );
                                    }

                                    let country = request.localStoreCountry;
                                    const find = countryCodes.findOne('countryCode' as CountryProperty, country);

                                    if (find) {
                                        country = `${find.flag} ${find.countryNameEn}`;
                                    }

                                    return (
                                        <>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.localStoreName}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.localStoreStreet}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.localStorePostalCode + ' ' + request.localStoreCity}
                                            </Typography>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {country}
                                            </Typography>
                                        </>
                                    )
                                }}
                            />
                            <TextField
                                source="localStorePhoneNumber"
                                label={"resources.forms.customerApplication.fields.localStorePhoneNumber"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="localStoreEmail"
                                label={"resources.forms.customerApplication.fields.localStoreEmail"}
                                emptyText={'n/a'}
                            />
                            <FunctionField
                                label={false}
                                source="isOnlineBusiness"
                                name="isOnlineBusiness"
                                render={(request: CustomerApplication) => {
                                    if (request.onlineBusinessWebsite) {
                                        return (
                                            <Labeled
                                                label={"resources.forms.customerApplication.fields.onlineBusinessWebsite"}>
                                                <Typography variant="inherit" color="text.primary" component={"p"}>
                                                    {request.onlineBusinessWebsite}
                                                </Typography>
                                            </Labeled>
                                        );
                                    }

                                    return (
                                        <Labeled label={"resources.forms.customerApplication.fields.isOnlineBusiness"}>
                                            <Typography variant="inherit" color="text.primary" component={"p"}>
                                                {request.isOnlineBusiness}
                                            </Typography>
                                        </Labeled>
                                    );
                                }}
                            />
                            <TextField
                                source="interestedIn"
                                label={"resources.forms.customerApplication.fields.interestedIn"}
                                emptyText={'n/a'}
                            />
                            <FunctionField
                                label={"resources.forms.customerApplication.fields.contactPerson"}
                                source="contactPerson"
                                name="contactPerson"
                                render={(request: CustomerApplication) => {
                                    return (
                                        <Typography variant="inherit" color="text.primary" component={"p"}>
                                            {`${request.contactPerson} (${request.contactPersonFunction})`}
                                        </Typography>
                                    );
                                }}
                            />
                            <TextField
                                source="contactPersonEmail"
                                label={"resources.forms.customerApplication.fields.contactPersonEmail"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="contactPersonPhoneNumber"
                                label={"resources.forms.customerApplication.fields.contactPersonPhoneNumber"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="contractDetails"
                                label={"resources.forms.customerApplication.fields.contractDetails"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="ordersManagementEmail"
                                label={"resources.forms.customerApplication.fields.ordersManagementEmail"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="vat"
                                label={"resources.forms.customerApplication.fields.vat"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="membershipAffiliation"
                                label={"resources.forms.customerApplication.fields.membershipAffiliation"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="membershipNumber"
                                label={"resources.forms.customerApplication.fields.membershipNumber"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="interestedIn"
                                label={"resources.forms.customerApplication.fields.interestedIn"}
                                emptyText={'n/a'}
                            />
                            <TextField
                                source="questions"
                                label={"resources.forms.customerApplication.fields.questions"}
                                emptyText={'n/a'}
                            />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Box>
        </Show>
    )
};
