import * as React from 'react';
import {
    Identifier,
    Datagrid,
    DateField,
    TextField,
    EmailField,
    BooleanField, FunctionField, useTranslate,
} from 'react-admin';

import rowSx from './rowSx';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {HitchRequest} from "../utils/types";

export interface HitchRequestsListDesktopProps {
    selectedRow?: Identifier;
}

const HitchRequestsListDesktop = ({selectedRow}: HitchRequestsListDesktopProps) => {
    const translate = useTranslate();

    useDefineAppLocation('forms.hitch_requests');
    return (
        <Datagrid
            rowClick="edit"
            rowSx={rowSx(selectedRow)}
            optimized
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-thead': {
                    borderLeftColor: 'transparent',
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                },
                '& .column-comment': {
                    maxWidth: '18em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            }}
        >

            <TextField
                source="hitchType"
                label="resources.reports.reports.hitchRequest.hitch_type"
            />
            <DateField
                source="createdAt"
                label="resources.reports.reports.hitchRequest.requested_at"
            />
            <TextField
                source="firstMeasurement"
                label="resources.reports.reports.hitchRequest.first_measurement"
                sortable={false}
            />
            <TextField
                source="secondMeasurement"
                label="resources.reports.reports.hitchRequest.second_measurement"
                sortable={false}
            />
            <FunctionField
                source="status"
                name="status"
                label={`resources.reports.reports.hitchRequest.status`}
                sortable={true}
                render={(request: HitchRequest) => {
                    return request.status === 'open' ? translate('resources.reports.reports.hitchRequest.open') : translate('resources.reports.reports.hitchRequest.close');
                }}
            />
        </Datagrid>
    );
}

export default HitchRequestsListDesktop;
