import * as React from 'react';
import {useState} from "react";

import {
    List,
    TextField,
    useTranslate,
    DatagridConfigurable,
    TopToolbar,
    SelectColumnsButton, ExportButton, useStore, downloadCSV, FilterButton
} from 'react-admin';
import {useMediaQuery, Theme} from '@mui/material';

import {useDefineAppLocation} from '@react-admin/ra-navigation';
import {AxleComplaint} from "../types";
import jsonExport from 'jsonexport/dist';
import axleComplaintsFilters from './axleComplaintsFilters';

const AxleComplaintListActions = () => {
    const [backdrop, handleBackdrop] = useState(false);
    const toggleBackdrop = () => {
        handleBackdrop(!backdrop);
    };

    return (
        <TopToolbar>
            <FilterButton/>
            <SelectColumnsButton preferenceKey="forms.axleComplaint.list"/>
            <ExportButton maxResults={10000} onClick={() => toggleBackdrop()}/>
        </TopToolbar>
    );
};

const AxleComplaintList = () => {
    useDefineAppLocation('forms.axle_complaints');
    const t = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    let [availableColumns] = useStore('preferences.forms.axleComplaint.list.availableColumns');
    let [selectedColumns] = useStore('preferences.forms.axleComplaint.list.columns');

    const exporter = (complaints: AxleComplaint[]) => {
        const forExport = complaints.map(complaint => {

            if (selectedColumns !== undefined) {

                let addressToExport = {};

                for (let i = 0; i < selectedColumns.length; i++) {
                    const column = availableColumns.find((column) => column.index === selectedColumns[i]);
                    if (column) {
                        addressToExport[t(`resources.forms.axleComplaint.fields.${column.source}`)] = complaint[column.source];
                    }
                }

                return addressToExport;
            }

            return {
                [t(`resources.forms.axleComplaint.fields.notifierType`)]: complaint.notifierType,
                [t(`resources.forms.axleComplaint.fields.name`)]: complaint.name,
                [t(`resources.forms.axleComplaint.fields.surname`)]: complaint.surname,
                [t(`resources.forms.axleComplaint.fields.email`)]: complaint.email,
                [t(`resources.forms.axleComplaint.fields.trailerCategory`)]: complaint.trailerCategory,
                [t(`resources.forms.axleComplaint.fields.createdAt`)]: complaint.createdAt
            };
        });
        jsonExport(forExport, {}, (err, csv) => {
            downloadCSV(csv, 'axle_complaints_list');
        });
    };
    const filters: JSX.Element[] = axleComplaintsFilters();

    return (
        <List
            exporter={exporter}
            filters={filters}
            perPage={25}
            actions={<AxleComplaintListActions/>}
            sx={{marginTop: isSmall ? undefined : 1}}
        >
            <DatagridConfigurable
                omit={[
                    'companyName', 'phoneNumber', 'clientId', 'postalCode',
                    'city', 'street', 'houseNumber', 'serialNumber', 'purchaseDate', 'axleSide', 'isBroken',
                    'isBentOnly', 'isWheelJammed', 'isWheelLoss', 'isBoughtNew',
                    'orderInformation', 'orderNumber', 'invoiceNumber', 'bikeType', 'frequency',
                    'trailerSuffered', 'someoneHurt'
                ]}
                preferenceKey="forms.axleComplaint.list"
                bulkActionButtons={false}
                rowClick="show"
            >
                <TextField source="notifierType" label={`resources.forms.axleComplaint.fields.notifierType`} sortable={true}/>
                <TextField source="name" label={`resources.forms.axleComplaint.fields.name`} sortable={false}/>
                <TextField source="surname" label={`resources.forms.axleComplaint.fields.surname`} sortable={false}/>
                <TextField source="email" label={`resources.forms.axleComplaint.fields.email`} sortable={true}/>
                <TextField source="trailerCategory" label={`resources.forms.axleComplaint.fields.trailerCategory`} sortable={true}/>
                <TextField source="createdAt" label={`resources.forms.axleComplaint.fields.createdAt`} sortable={true}/>
                <TextField source="companyName" label={`resources.forms.axleComplaint.fields.companyName`} sortable={true}/>
                <TextField source="phoneNumber" label={`resources.forms.axleComplaint.fields.phoneNumber`} sortable={false}/>
                <TextField source="clientId" label={`resources.forms.axleComplaint.fields.clientId`} sortable={true}/>
                <TextField source="postalCode" label={`resources.forms.axleComplaint.fields.postalCode`} sortable={true}/>
                <TextField source="city" label={`resources.forms.axleComplaint.fields.city`} sortable={true}/>
                <TextField source="street" label={`resources.forms.axleComplaint.fields.street`} sortable={false}/>
                <TextField source="houseNumber" label={`resources.forms.axleComplaint.fields.houseNumber`} sortable={false}/>
                <TextField source="serialNumber" label={`resources.forms.axleComplaint.fields.serialNumber`} sortable={true}/>
                <TextField source="axleSide" label={`resources.forms.axleComplaint.fields.axleSide`} sortable={false}/>
                <TextField source="isBroken" label={`resources.forms.axleComplaint.fields.isBroken`} sortable={false}/>
                <TextField source="isBentOnly" label={`resources.forms.axleComplaint.fields.isBentOnly`} sortable={false}/>
                <TextField source="isWheelJammed" label={`resources.forms.axleComplaint.fields.isWheelJammed`} sortable={false}/>
                <TextField source="isWheelLoss" label={`resources.forms.axleComplaint.fields.isWheelLoss`} sortable={false}/>
                <TextField source="isBoughtNew" label={`resources.forms.axleComplaint.fields.isBoughtNew`} sortable={false}/>
                <TextField source="orderInformation" label={`resources.forms.axleComplaint.fields.orderInformation`} sortable={false}/>
                <TextField source="orderNumber" label={`resources.forms.axleComplaint.fields.orderNumber`} sortable={true}/>
                <TextField source="invoiceNumber" label={`resources.forms.axleComplaint.fields.invoiceNumber`} sortable={true}/>
                <TextField source="bikeType" label={`resources.forms.axleComplaint.fields.bikeType`} sortable={true}/>
                <TextField source="frequency" label={`resources.forms.axleComplaint.fields.frequency`} sortable={false}/>
                <TextField source="trailerSuffered" label={`resources.forms.axleComplaint.fields.trailerSuffered`} sortable={false}/>
                <TextField source="someoneHurt" label={`resources.forms.axleComplaint.fields.someoneHurt`} sortable={false}/>
            </DatagridConfigurable>
        </List>
    );
};

export default AxleComplaintList;
