import * as React from 'react';
import {FC} from 'react';
import {useRecordContext, List, Datagrid, DateField, useTranslate} from 'react-admin';
import {Box, Typography} from '@mui/material';
import StarRatingField from './StarRatingField';
import {Order} from "../types";
import ReviewShow from './ReviewShow';

export const AsideReviewList: FC = props => {
    const record: Order = useRecordContext(props);
    const translate = useTranslate();
    if (!record) return null;

    return (
        <Box mt={{xs: '20px', md: '0'}} ml={{xs: '0', md: '20px'}} width={{xs: '100%', md: '90%'}}>
            <Typography variant="h6" gutterBottom>
                {translate('resources.order.reviews.list_label')}
            </Typography>
            <List
                title=" "
                resource={`order-reviews`}
                filter={{orderElement: record.id}}
                pagination={false}
                hasCreate={false}
                empty={false}
                sx={{width: '100%'}}
                actions={false}
            >
                <Datagrid bulkActionButtons={false} rowClick="expand" expand={<ReviewShow/>}>
                    <StarRatingField
                        size="small"
                        sortable={true}
                        label="resources.order.reviews.rate"
                    />
                    <DateField
                        source="createdAt"
                        key="createdAt"
                        sortable={true}
                        label="resources.order.reviews.createdAt"
                    />
                </Datagrid>
            </List>
        </Box>
    );
};
