import * as React from 'react';
import {DateInput, SelectInput, TextInput,} from 'react-admin';
import { applicationStates } from './applicationStates';

const customerApplicationsFilters = () => {
    return [
        <SelectInput
            alwaysOn
            source="status"
            choices={Object.values(applicationStates)}
            label={`resources.forms.customerApplication.fields.status`}
            optionText="translationKey"
        />,
        <TextInput
            key="companyName"
            source="companyName"
            name="companyName"
            label={`resources.forms.customerApplication.fields.companyName`}
        />,
        <TextInput
            key="requestId"
            source="requestId"
            name="requestId"
            label={`resources.forms.customerApplication.fields.requestId`}
        />,
        <DateInput
            source="createdAt_gte"
            name="createdAt_gte"
            label={`resources.forms.customerApplication.requested_since`}
        />,
        <DateInput
            source="createdAt_lte"
            name="createdAt_lte"
            label={`resources.forms.customerApplication.requested_before`}
        />
    ];
}

export default customerApplicationsFilters;
