import * as React from 'react';
import {
    CustomRoutes,
    Resource,
    useStore,
    StoreContextProvider,
    localStorageStore, DataProvider
} from 'react-admin';
import {Admin, buildI18nProvider} from '@react-admin/ra-enterprise';
import CssBaseline from '@mui/material/CssBaseline';
import {Route} from 'react-router';

import authProvider from './authProvider';
import englishMessages from './i18n/en';
import germanMessages from './i18n/de';
import frenchMessages from './i18n/fr';
import {Login, Layout, MultiFactorAuthentication, ResetPassword} from './layout';
import {Dashboard} from './dashboard';
import order from './order';
import products from './products';
import user from './user';
import coupon from './coupon';
import cronJobs from './server';
import backInStock from './backInStock';
import attribute from './attribute';
import pma from './productsAllocations';
import marketplaces from './marketplace';
import {CategoriesList} from './category/Category';
import categories from './category';
import dealers from './dealers';
import dealersLocations from './dealers/locations';
import reports from './reports';
import hitchRequests from './hitchRequest';
import customerApplication from './customerApplications';
import axleComplaint from './axleComplaint';

import Configuration from './configuration/Configuration';
import {ThemeName, themes} from './themes/themes';
import dataProviderFactory from './dataProvider';

const messages = {
    en: englishMessages,
    de: germanMessages,
    fr: frenchMessages,
};

const i18nProvider = buildI18nProvider(messages, 'en', [
    {locale: 'en', name: 'English'},
    {locale: 'de', name: 'German'},
    {locale: 'fr', name: 'French'},
]);

export const appKey: string = 'CroozerMS'

const store = localStorageStore(undefined, appKey);

const App = ({dataProvider}: { dataProvider: DataProvider }) => {
    const [themeName] = useStore<ThemeName>('themeName', 'soft');
    const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    const darkTheme = themes.find(theme => theme.name === themeName)?.dark;

    return (
        <Admin
            title=""
            store={store}
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
        >
            <CssBaseline/>
            <Resource name="user" {...user} />
            <Resource name="order" {...order} />
            <Resource name="coupon" {...coupon} />
            <Resource name="server/cron-jobs" {...cronJobs} />
            <Resource name="products" {...products} />
            <Resource name="back-in-stock" {...backInStock} />
            <Resource name="marketplaces" {...marketplaces} />
            <Resource name="categories" {...categories} />
            <Resource name="category-trees" list={CategoriesList}/>
            <Resource name="attributes" {...attribute} />
            <Resource name="product-marketplace-allocations" {...pma} />
            <Resource name="dealers" {...dealers} />
            <Resource name="dealer-addresses" {...dealersLocations} />
            <Resource name="reports" {...reports} />
            <Resource name="hitch-requests" {...hitchRequests} />
            <Resource name="customer-applications" {...customerApplication} />
            <Resource name="axle-complaints" {...axleComplaint} />
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration/>}/>
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route
                    path="/mfa"
                    element={<MultiFactorAuthentication/>}
                />
                <Route
                    path="/reset-password"
                    element={<ResetPassword/>}
                />
            </CustomRoutes>
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App dataProvider={dataProviderFactory}/>
    </StoreContextProvider>
);

export default AppWrapper;
