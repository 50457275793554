import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Link
} from '@mui/material';
import {useTranslate, useRecordContext} from 'react-admin';

import {Order} from '../types';
import {TableCellRight} from './TableCellRight';
import RefundedItemChip from './RefundedItemChip';

const Price = ({item}: { item: any }) => {

    if (item.discountPercentage == 0) {
        return item.priceWithTax.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencySign: 'accounting',
        })
    }

    return (
        <>
            <div style={{textDecoration: "line-through", fontSize: "small", color: "#D32F2F"}}>
                {item.priceWithTax.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencySign: 'accounting',
                })}
            </div>
            {item.unitPriceWithTaxAndDiscount.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencySign: 'accounting',
            })}
        </>
    )
}
const Total = ({item}: { item: any }) => {

    if (item.isFree) {
        return "Free";
    }

    if (item.discountPercentage == 0) {
        return item.lineTotalWithTax.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencySign: 'accounting',
        });
    }

    const total = item.unitPriceWithTaxAndDiscount * item.quantity;

    return total.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencySign: 'accounting',
    })
}

const Basket = () => {
    const record = useRecordContext<Order>();
    const translate = useTranslate();

    if (!record) return null;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        {translate('resources.order.show.product_name')}
                    </TableCell>
                    <TableCell>
                        {translate('resources.order.show.product_sku')}
                    </TableCell>
                    <TableCellRight>
                        {translate('resources.order.show.product_price')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.order.show.product_quantity')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.order.show.product_total')}
                    </TableCellRight>
                    <TableCellRight>
                        {translate('resources.order.status')}
                    </TableCellRight>
                </TableRow>
            </TableHead>
            <TableBody>
                {record?.products?.map((item: any) => (
                    <TableRow key={item.id}>
                        <TableCell>
                            {item.productId ? ( <Link
                                href={`/#/products/${item.productId}`}
                                target="_blank"
                                rel="noopener"
                            >
                                {item.name}
                            </Link>) : item.sku}
                        </TableCell>
                        <TableCell>{item.sku}</TableCell>
                        <TableCellRight>
                            <Price item={item}/>
                        </TableCellRight>
                        <TableCellRight>{item.quantity}</TableCellRight>
                        <TableCellRight>
                            <Total item={item}/>
                        </TableCellRight>
                        <TableCellRight>
                            <RefundedItemChip {...item} />
                        </TableCellRight>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default Basket;
